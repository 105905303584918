<template>
  <section class="choose-checkCandidate-router">
    <edit v-show="componentName==='edit'" @changeComponent="changeComponent" ref="edit" :dic="dic"></edit>
    <exportPDF v-if="componentName==='exportPDF'" @changeComponent="changeComponent" ref="exportPDF" :dic="dic"></exportPDF>
    <index v-show="componentName==='index'" @changeComponent="changeComponent" ref="index" :dic="dic"></index>
  </section>
</template>

<script>
import index from './components/index';
import edit from './components/edit';
import exportPDF from './components/exportPDF';
export default {
  // 候选人资格审查
  name: 'checkCandidate',
  props: {},
  components: {
    index,
    edit,
    exportPDF,
  },
  data() {
    return {
      componentName: '',
    };
  },
  methods: {
    changeComponent(name, data) {
      this.componentName = name;
      if (name === 'index') {
        this.$nextTick(() => {
          this.$refs.index.toggle();
        });
        return;
      }
      if (name === 'edit') {
        this.$nextTick(() => {
          this.$refs.edit.toggle(data, 'edit');
        });
        return;
      }
      if (name === 'exportPDF') {
        this.$nextTick(() => {
          this.$refs.exportPDF.toggle(data, 'exportPDF');
        });
      }
    },
  },
  mounted() {},
  created() {
    this.dic = this.$store.getters.dic;
    this.changeComponent('index');
    this.$nextTick(() => {
      this.$refs.index.toggle();
    });
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.choose-checkCandidate-router {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  .btn_active {
    border-color: $primary;
  }

  .header {
    padding-bottom: $space_small;
  }
  .el-main {
    flex: 1;
    background-color: #fff;
  }
}
</style>
